// 固定路由（默认路由）
import Layout from '@/layout/index.vue'
import mobileLayout from '@/mobile-layout/index.vue'
const isMobile =
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent,
  )

const pcMenuRoutes = [
  {
    path: 'index',
    meta: {
      title: '首页',
      fullPath: '/home/index',
    },
    component: () => {
      return import('@/views/home/index.vue')
    },
  },
  {
    path: 'product',
    name: 'product',
    meta: {
      title: '晨星产品',
      fullPath: '/home/product',
    },
    component: () => {
      return import('@/views/product/index.vue')
    },
  },
  {
    path: 'solution',
    name: 'solution',
    meta: {
      title: '晨星方案',
      fullPath: '/home/solution',
    },
    component: () => {
      return import('@/views/solution/index.vue')
    },
  },
  {
    isSlot: true,
    path: 'try',
    name: 'try',
    meta: {
      title: '系统试用',
      fullPath: '/home/try',
    },
    children: [
      {
        path: 'https://remote.daystar.lenovo.com',
        outLink: true,
        meta: {
          title: '联想晨星远程协作系统',
        },
      },
      {
        path: 'https://daystar.lenovo.com/aa-training',
        outLink: true,
        meta: {
          title: '联想晨星XR实训系统',
        },
      },
      {
        path: 'https://exhibition.lenovo-mr.com/#/ue4list/index',
        outLink: true,
        meta: {
          title: '元宇宙展厅',
        },
      },
      {
        path: 'tooldownload',
        name: 'tooldownload',
        meta: {
          title: 'AR工具&应用',
          fullPath: '/toolDownload',
        },
      },
      {
        path: 'https://apps.apple.com/cn/app/%E6%99%A8%E6%98%9F%E6%89%AB%E6%8F%8F%E4%BB%AA/id1538419841',
        outLink: true,
        meta: {
          title: '联想晨星3D扫描：stARscan',
        },
      },
      {
        path: 'sdkDownload',
        name: 'sdkDownload',
        meta: {
          title: '联想晨星SDK：stARkit',
          fullPath: '/sdkDownload',
        },
      },
      // {
      //   path: 'https://daystar.lenovo.com/redmine/',
      //   outLink: true,
      //   meta: {
      //     title: '客户支持系统',
      //   },
      // },
    ],
  },
  {
    path: 'about',
    name: 'about',
    meta: {
      title: '关于我们',
      fullPath: '/home/about',
    },
    component: () => {
      return import('@/views/about/index.vue')
    },
  },
  {
    path: 'develop',
    name: 'develop',
    meta: {
      title: '开发者平台',
      fullPath: '/home/develop',
    },
    redirect: '/home/develop/kit',
    children: [
      {
        path: 'kit',
        meta: {
          title: '联想晨星SDK:stARkit',
          fullPath: '/home/develop/kit',
        },
        component: () => {
          return import('@/views/starkit/index.vue')
        },
      },
      {
        path: 'https://daystar.lenovo.com/dev/#/login/login',
        outLink: true,
        meta: {
          title: '晨星视觉平台',
        },
      },
    ],
  },
  {
    path: 'exhibition',
    name: 'exhibition',
    meta: {
      title: '元宇宙展示',
      fullPath: 'exhibition'
    },
    redirect: 'https://exhibition.lenovo-mr.com/#/ue4list/index',
    children: [
      {
        path: 'https://exhibition.lenovo-mr.com/#/ue4list/index',
        outLink: true,
        meta: {
          title: '未来中心元宇宙会议',
        },
        component: () => {
          return import('@/views/starkit/index.vue')
        },
      },
      {
        path: 'https://daystar.lenovo.com/nerf/#/',
        outLink: true,
        meta: {
          title: 'TechWorld 2024三维展厅',
        },
      },
      {
        path: 'https://www.lenovo-mr.com/',
        outLink: true,
        meta: {
          title: 'Daystar World',
        },
      },
    ],
  },
]
const mobileMenuRoutes = [
  {
    path: 'index',
    meta: {
      title: '首页',
      fullPath: '/home/index',
    },
    component: () => {
      return import('@/views/mobile-views/home/index.vue')
    },
  },
  {
    path: 'product',
    name: 'product',
    meta: {
      title: '晨星产品',
      fullPath: '/home/product',
    },
    component: () => {
      return import('@/views/mobile-views/product/index.vue')
    },
  },
  {
    path: 'solution',
    name: 'solution',
    meta: {
      title: '晨星方案',
      fullPath: '/home/solution',
    },
    component: () => {
      return import('@/views/mobile-views/solution/index.vue')
    },
  },
  {
    isSlot: true,
    path: 'try',
    name: 'try',
    meta: {
      title: '系统试用',
      fullPath: '/home/try',
    },
    children: [
      {
        path: 'https://remote.daystar.lenovo.com',
        outLink: true,
        meta: {
          title: '联想晨星远程协作系统',
        },
      },
      {
        path: 'https://daystar.lenovo.com/aa-training',
        outLink: true,
        meta: {
          title: '联想晨星XR实训系统',
        },
      },
      {
        path: 'https://exhibition.lenovo-mr.com/#/ue4list/index',
        outLink: true,
        meta: {
          title: '元宇宙展厅',
        },
      },
      {
        path: 'toolDownload',
        name: 'toolDownload',
        meta: {
          title: 'AR工具&应用',
          fullPath: '/toolDownload',
        },
      },
      {
        path: 'https://apps.apple.com/cn/app/%E6%99%A8%E6%98%9F%E6%89%AB%E6%8F%8F%E4%BB%AA/id1538419841',
        outLink: true,
        meta: {
          title: '联想晨星3D扫描：stARscan',
        },
      },
      {
        path: 'sdkDownload',
        name: 'sdkDownload',
        meta: {
          title: '联想晨星SDK：stARkit',
          fullPath: '/sdkDownload',
        },
      },
      // {
      //   path: 'https://daystar.lenovo.com/redmine/',
      //   outLink: true,
      //   meta: {
      //     title: '客户支持系统',
      //   },
      // },
    ],
  },
  {
    path: 'about',
    name: 'about',
    meta: {
      title: '关于我们',
      fullPath: '/home/about',
    },
    component: () => {
      return import('@/views/mobile-views/about/index.vue')
    },
  },

  {
    path: 'develop',
    name: 'develop',
    meta: {
      title: '开发者平台',
      fullPath: '/home/develop',
    },
    redirect: '/home/develop/kit',
    children: [
      {
        path: 'index',
        meta: {
          title: '联想晨星SDK:stARkit',
          fullPath: '/home/develop/kit',
        },
        component: () => {
          return import('@/views/mobile-views/starkit/index.vue')
        },
      },
      {
        path: 'https://daystar.lenovo.com/dev/#/login/login',
        outLink: true,
        meta: {
          title: '晨星视觉平台',
        },
      },
    ],
  },
  {
    path: 'exhibition',
    name: 'exhibition',
    meta: {
      title: '元宇宙展示',
    },
    redirect: 'https://exhibition.lenovo-mr.com/#/ue4list/index',
    children: [
      {
        path: 'https://exhibition.lenovo-mr.com/#/ue4list/index',
        outLink: true,
        meta: {
          title: '未来中心元宇宙会议',
        },
        component: () => {
          return import('@/views/starkit/index.vue')
        },
      },
      {
        path: 'https://daystar.lenovo.com/nerf/#/',
        outLink: true,
        meta: {
          title: 'TechWorld 2024三维展厅',
        },
      },
      {
        path: 'https://www.lenovo-mr.com/',
        outLink: true,
        meta: {
          title: 'Daystar World',
        },
      },
    ],
  },
]

const menuRoutes = isMobile ? mobileMenuRoutes : pcMenuRoutes
const constantRoutes = [
  {
    path: '/',
    redirect: '/home/index',
  },
  {
    path: '/home',
    name: 'home',
    component: isMobile ? mobileLayout : Layout,
    redirect: '/home/index',
    children: menuRoutes,
  },
  {
    path: '/sdkDownload',
    innerAnchor: true,
    component: () => import('@/views/sdk-download/index.vue'),
    meta: {
      title: 'SDK下载及说明文档',
      fullPath: '/sdkDownload',
    },
  },
  {
    path: '/toolDownload',
    innerAnchor: true,
    component: () => import('@/views/cast-download/index.vue'),
    meta: {
      title: '工具下载',
      fullPath: '/toolDownload',
    },
  },
  {
    path: '/privacyStatement',
    innerAnchor: true,
    component: () => import('@/views/privacy-statement/index.vue'),
    meta: {
      title: '隐私权限',
    },
  },
  {
    path: '/legalNotice',
    innerAnchor: true,
    component: () => import('@/views/legalNotice/index.vue'),
    meta: {
      title: '法律公共',
    },
  },
]

export { constantRoutes, menuRoutes }
